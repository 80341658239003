interface EditInputProps {
  modelValue: number|string
  onValueChange: (v: number|number) => void
  onValueClear: () => void
}

export const EditInput = ({ modelValue, onValueChange, onValueClear }: EditInputProps) => {
  return (
    <label class="edit-input-label">
      <input
        class="edit-input-input"
        type="text" value={modelValue}
        onInput={(e: any) => onValueChange(e.target?.value)}
        placeholder={modelValue === null || '' ? 'Пусто' : ''}
      />
      <img onClick={onValueClear} class="edit-input-clear" src={require('../assets/image/clear-icon.svg')} alt="X"/>
    </label>
  )
}
