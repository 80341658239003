import { mutations, store } from '@/store'
import { DefaultInput, ModalForm, NumberInput, usePaginatedApi, useScrollPagination } from 'apptimizm-ui'
import axios from '@/axios'
import { computed, ComputedRef, defineComponent, PropType, Ref, ref, watch } from 'vue'
import { Product, ProductMeta } from '../products/types'
import { DocumentMeta } from './types'

interface IForm {
  amount: number
  item: Product | undefined
}

export default defineComponent({
  props: {
    toggle: {
      type: Function as PropType<() => void>,
      required: true
    },
    documentId: {
      type: String,
      required: true
    },
    reload: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup (props) {
    const form = ref<IForm>({ amount: 0, item: undefined })
    const productMeta = new ProductMeta()
    const searchParams = ref<{[ key: string ]: string}>({
      barcode: ''
    })
    const searchBarcode = ref('')
    const documentMeta = new DocumentMeta()
    const triger = ref(null) as unknown as Ref<HTMLElement>
    const { items: products, load, reload } = usePaginatedApi(
      `${productMeta.endpoint}?project=${store.currentProject.id}`,
      axios,
      searchParams,
      ref(20),
      'page',
      (v: any) => productMeta.load(v),
      true,
      'results',
      'count'
    )

    useScrollPagination(load, triger)
    watch(searchBarcode, to => {
      searchParams.value.barcode = to
      reload()
    })

    const sumbit = async () => {
      try {
        const { data } = await axios.post(documentMeta.endpoint + props.documentId + '/add-product-position/', {
          amount: form.value.amount,
          barcode: form.value.item?.barcode
        })
        if (!data.errors) {
          mutations.pushNotification('Товар успешно добавлен')
          props.toggle()
          props.reload()
        } else {
          const error: string = Object.keys(data.errors).map(key => `${key} - ${data.errors[key]}`).join('; ')
          mutations.pushNotification(`Ошибка при добавлении товара: ${error}`, true)
        }
      } catch (e) {
        mutations.pushNotification('Ошибка при добавлении товара', true)
      }
    }

    const disabled: ComputedRef<boolean> = computed(() => {
      if (form.value.amount < 1) return true
      if (!form.value.item?.barcode) return true
      return false
    })

    return () => (
      <div>
        <ModalForm
          cancel={props.toggle}
          confirm={() => {}}
          header='Добавить'
          slots={{
            default: () => (
              <div class='mt-3 mb-2'>
                <DefaultInput
                  class={searchBarcode.value.length ? 'input-placeholder-up' : ''}
                  placeholder='Штрихкод'
                  modelValue={searchBarcode.value}
                  onValueChange={(v: string) => { searchBarcode.value = v }}
                />
                <label class='mt-2'>
                  <NumberInput
                    class={`${!form.value.amount ? 'hide-zero' : 'input-placeholder-up'}`}
                    placeholder="Количество"
                    modelValue={form.value.amount}
                    onValueChange={(v: number) => { form.value.amount = parseInt(String(v)) } }
                  />
                </label>
                <div class='products-container mt-2'>
                  {products.value.map((product: Product) => <div
                    class={{ 'product-item mb-1': true, active: form.value.item?.id === product.id }}
                    onClick={() => { form.value.item = product }}
                  >
                    <span>{product.name}</span>
                    <div class='product-item-row mt-1'>
                      <span>Штрихкод:</span>
                      <span>{product.barcode}</span>
                    </div>
                    <div class='product-item-row'>
                      <span>Код товара:</span>
                      <span>{product.vendorCode}</span>
                    </div>
                  </div>)}
                  <div ref={triger}/>
                </div>
              </div>
            ),
            buttons: () => <button disabled={disabled.value} onClick={sumbit} class='primary-button'>Сохранить</button>
          }}
        />
      </div>
    )
  }
})
